@import '~/bb/ui/Styles/variables';

.light {
    color: var(--color-primary-black);
    background-color: var(--color-primary-white);
}

.dark {
    color: var(--color-primary-white);
    background-color: var(--color-gray-06);
}

.flex {
    flex-wrap: nowrap;
    gap: var(--distance-extra-small);
    align-items: stretch;
    width: 100%;
    max-width: 1354px;
    overflow-x: auto;
}

.maxWidthContainer {
    flex: 1 0 280px;
    max-width: 440px;
}

.box {
    height: 100%;
}

.card {
    color: var(--color-primary-black);
}

.card:first-child {
    margin-left: var(--distance-small);

    @media (width >= 992px) {
        margin-left: var(--distance-medium);
    }
}

.card:last-child {
    margin-right: var(--distance-small);

    @media (width >= 992px) {
        margin-right: var(--distance-medium);
    }
}

.wideButton {
    max-width: none;

    @media (width >= $screen-size-xs) {
        max-width: 300px;
    }
}

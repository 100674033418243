@import '~/bb/ui/Styles/variables';

.divider {
    width: 100%;
    height: 1px;
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--color-gray-02);

    @media only screen and (width >= 720px) {
        max-width: 800px;
    }
}

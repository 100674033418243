@import '~/bb/ui/Styles/variables';

.box {
    flex: 1;
}

.backgroundImage {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    padding: 60px;
    text-align: center;
    background: url('https://images.ctfassets.net/4s7izhcdroyy/l1dbLqkE3hgexKIrNgQR8/b4502a4dc6afed30147426c164b65036/11_Something_for_everyone_primaryPurple.jpg?h=600&q=85');
    background-position: center;
    background-size: 120%;

    @media screen and (min-width: $screen-size-lg) {
        padding: 100px;
    }
}

@import '~/bb/ui/Styles/variables';

.base {
    position: relative;
    width: 100%;
}

.header {
    margin-bottom: var(--distance-extra-small);
}

.title {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-left: var(--distance-extra-small);
    margin: 0;
    word-wrap: break-word;

    a {
        display: flex;
        align-items: center;
        text-decoration: none;

        svg {
            display: inline-block;
        }
    }

    a:hover,
    a:active {
        text-decoration: underline;
    }
}

.skipBtn {
    position: absolute;
    left: -9999999px;
}

.skipBtn:focus-visible {
    position: relative;
    left: auto;
    border: 2px solid var(--color-primary-white);
}

.carousel {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.contentContainer {
    display: flex;
    transition: 0.5s;

    > * {
        margin: 0 0.5rem;
    }

    > *:last-child {
        padding-right: 0.5rem;
    }

    > *:first-child {
        margin-left: var(--distance-extra-small);
    }
}

.button {
    position: absolute;
    top: calc(50% - 32px);
    z-index: 2;
    box-sizing: border-box;
    display: none;
    width: 64px;
    height: 64px;
    padding: 0.5rem;
    margin: 0;
    cursor: pointer;
    background-color: rgb(0 0 0 / 40%);
    border: none;

    @media screen and (width >= 576px) {
        display: block;
    }

    &:disabled {
        svg {
            fill: var(--color-gray-03);
        }
    }

    &:focus,
    &:hover:not(:disabled) {
        svg {
            fill: var(--color-primary-purple);
        }
    }

    &:active {
        background-color: rgb(0 0 0 / 60%);
    }
}

.rightButton {
    right: 0;
}

.leftButton {
    left: 0;
}

@import '~/bb/ui/Styles/variables';

.base {
    display: none;
    width: 100%;
    height: var(--distance-large);
    background-color: var(--empty-block-background-color);

    @media screen and (width >= 913px) {
        display: block;
    }
}

@import '~bb/ui/Styles/variables';

.grid {
    display: grid;
    grid-auto-columns: 1fr;
    place-items: center;

    @media screen and (width >= $grid-breakpoint) {
        grid-auto-flow: column;
    }
}

.column {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--grid-background-color);
}

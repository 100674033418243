.root {
    min-width: 200px;

    & svg {
        animation: spin 3s linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

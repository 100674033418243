// Override below
.inlineChildren.inlineChildren {
    & > * {
        display: inline-block;
    }
}

.animated {
    overflow: hidden;

    & > * {
        display: block;
        line-height: 115%;
        transition: transform cubic-bezier(1, 0, 0, 1);
        transition-delay: 0.2s;
        transform: translateY(100%);
    }

    &.show {
        & > * {
            transition: transform 0.8s cubic-bezier(1, 0, 0, 1);
            transform: translateY(0);

            &:last-child {
                transition-delay: 0.4s;
            }
        }
    }
}

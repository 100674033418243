.padding {
    padding-right: 20px !important;
    padding-left: 20px !important;

    @media screen and (width >= 768px) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
}

.block {
    display: flex;
    justify-content: center;
    height: 100%;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.align-stretch {
    align-items: stretch;
}

.grow {
    flex-grow: 1;
}

@import '~/bb/ui/Styles/functions';

.root {
    display: flex;
    height: var(--button-height);
    padding: spacing(4, 0);
    font-weight: bold;
    white-space: nowrap;

    @media screen and (max-width: $screen-size-sm) {
        justify-content: center;
        width: 100%;
    }
}

.align-left {
    justify-content: flex-start;

    @media screen and (max-width: $screen-size-sm) {
        justify-content: center;
    }
}

.align-right {
    justify-content: flex-end;

    @media screen and (max-width: $screen-size-sm) {
        justify-content: center;
    }
}

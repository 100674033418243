@import '~/bb/ui/Styles/variables';

.buttonWrapper {
    bottom: 0;
    z-index: 1;
    justify-content: center;
    width: 100%;
    padding-top: var(--distance-small);
    padding-bottom: var(--distance-small);
    margin-top: 2px; // To not hide border of last card in mobile
    background-color: var(--color-primary-white);

    @media screen and (min-width: $screen-size-md) {
        position: initial;
        bottom: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        background-color: unset;
    }
}

.stickyButton {
    position: sticky;
}

.button {
    width: 100%;
    max-width: 320px;
}

$innerSize: 64px;
$fadedBoxSize: 80px;
$diffSize: $fadedBoxSize - $innerSize;
$fullSize: 100px;
$checkSize: calc($innerSize / 2);
$curve: cubic-bezier(0.65, 0, 0.45, 1);
$boxAnimationTime: 0.3s;

.root {
    position: relative;
    width: $fullSize;
    height: $fullSize;
}

.inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $innerSize;
    height: $innerSize;
    background-color: var(--success-animation-background-color);
    transform-origin: center;
    translate: -50% -50%;
    animation: $boxAnimationTime scale;

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $checkSize;
        height: $checkSize;
        stroke-dasharray: $checkSize;
        stroke-dashoffset: $checkSize;
        transform-origin: 50% 50%;
        translate: -50% -50%;
        animation: stroke 0.4s $curve 0.8s forwards;
        animation-delay: $boxAnimationTime;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.innerBox {
    position: absolute;
    top: 0;
    left: 0;
    width: $fadedBoxSize;
    height: $fadedBoxSize;
    background-color: inherit;
}

.darkInnerBox {
    opacity: 0.6;
}

.lightInnerBox {
    opacity: 0.3;
    transform: translate(-$diffSize, -$diffSize);
}

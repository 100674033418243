@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.container {
    flex-direction: column;
    gap: spacing(4);
    margin-top: 10px;

    @media only screen and (width >= 1080px) {
        flex-direction: row;
        gap: spacing(6);
        align-items: flex-start;
    }
}

.item {
    @media (width >= $screen-size-sm) {
        white-space: nowrap;
    }
}

@import '~/bb/ui/Styles/variables';

.heroBlock {
    min-height: 500px;

    @media screen and (max-width: $screen-size-lg) {
        min-height: 300px;
    }
}

.heroImage {
    max-width: clamp(calc(100px + 20vw), 100%, 600px);
    height: 100%;
}

.subHeroImage {
    height: 100%;
    object-fit: cover;
}

.leftBorder {
    border-left: 2px solid var(--color-primary-purple);
}

.column {
    display: grid;
    place-items: center;
}

.heroMargin {
    @media screen and (width >= 768px) {
        margin: var(--distance-large) 0 var(--distance-large) 0;
    }
}

.headerFluid {
    font-size: clamp(2.25rem, calc(0.1rem + 4.9vw), 4rem);

    &.extraSmall {
        font-size: clamp(1.875rem, calc(0.1rem + 4.2vw), 3.4rem);
    }
}

.content {
    min-height: 70vh;
}

.heroBlockImage {
    position: relative;
    display: block;
    width: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}

.colItem {
    max-height: var(--col-max-height);
}

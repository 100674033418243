@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

$animation-time: 0.2s;

.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    background-color: var(--checked-background-color);
    outline: 2px solid transparent;
    outline-offset: -2px;
    transition: outline $animation-time ease;

    & *:checked ~ & {
        outline: 2px solid var(--checked-outline);
    }

    & *:focus-visible ~ & {
        outline: 2px solid var(--color-primary-black) !important;
    }
}

.content {
    position: relative;
    min-height: 70px;
    padding: spacing(6, 8);
}

.discount {
    position: absolute;
    top: 0;
    right: 0;
    padding: spacing(1, 4);
    background-color: var(--color-primary-black);
}

// Override high specificity of Flex component
.stack.stack {
    align-items: center;

    @media screen and (min-width: $screen-size-lg) {
        flex-direction: row;
        align-items: stretch;
    }
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding: var(--distance-extra-small) var(--distance-medium);
    background-color: var(--box-background-color);

    & > * {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(4);
    margin-top: auto;
    background-color: transparent;
    transition: background-color $animation-time ease;

    & svg {
        opacity: 0;
    }

    &.isSelected {
        background-color: var(--button-background-color);

        & svg {
            opacity: 1;
            animation: grow $animation-time ease;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--divider-color);
}

@keyframes grow {
    0% {
        transform: scale(0.4);
    }

    70% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.radio {
    flex: 1;
    width: 100%;
    max-width: 300px;
}

.hide {
    visibility: hidden;
}

.strikethrough {
    text-decoration: line-through;
}

.isLoading {
    pointer-events: none;
    filter: grayscale(1);
}

@import '~/bb/ui/Styles/variables';

.leftBorder {
    border-left: 2px solid var(--color-primary-purple);
}

.column {
    display: grid;
    place-items: center;
    background-color: var(--usps-col-background-color);
}

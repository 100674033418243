@import '~/bb/ui/Styles/variables';

.base {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--navbar-height));
    margin-top: var(--navbar-height);
    background-color: var(--layout-with-menu-background-color);

    /* 
    * The layout will have 3 direct children - <nav>, (<main> || <div>) and <footer>
    * For pages with little content (combined with large screens) we need to fill potential vertical space with flex grow on the <main> child.
    */
    > :nth-child(2) {
        display: flex;
        flex-grow: 1; /* Fill remaining space, sometimes 70vh is less than reamaining space */
        flex-direction: column;
        min-height: 70vh; /* TODO: Remove this? Content should set the height */
    }
}

.skipBtn {
    position: sticky;
    top: 0;
    z-index: var(--z-index-nav);
    display: flex;
    width: 100%;
    background-color: var(--color-gray-05);
    transition:
        transform 0.3s ease,
        opacity 0.3s ease;

    &:focus-within {
        height: 40px;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    &:not(:focus-within) {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

.shifted {
    transform: translate3d(0, 40px, 0);
}

.noMenu {
    min-height: 100vh;
    margin-top: auto;
}

@import '~/bb/ui/Styles/variables';

.center {
    margin-right: auto;
    margin-left: auto;
}

.fullWidthInResponsive {
    @media (max-width: $screen-size-sm) {
        width: 100%;

        // Since we style the max-width inline we need to override that with !important.
        max-width: none !important;
    }
}

.withBackground {
    background: var(--color-primary-white);
}

.grow {
    flex: 1;
}

@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.base {
    margin-top: auto;
}

.bookbeat {
    max-width: 360px;

    @media screen and (width >= 992px) {
        max-width: 255px;
        margin-top: -8px;
    }

    > a {
        img {
            margin-left: -12px;
        }
    }
}

.marginLeft {
    margin-left: 10px;
}

/**
cards
**/
.paypal {
    height: 25px;
}

.klarna {
    height: 25px;
}

.apple {
    height: 25px;
}

.ideal {
    height: 25px;
}

.google {
    height: 25px;
}

.navigationRow {
    @media screen and (width >= 992px) {
        padding-left: var(--distance-small);
    }
}

.navigationRow .navList {
    flex: 1 1 100%;
    margin-top: var(--distance-small);

    @media screen and (width >= 487px) {
        flex: 1 1 50%;
    }

    @media screen and (width >= 992px) {
        flex: 1 1 33%;

        // Selects the first 3 elements with classname navList
        &:nth-child(-n + 3) {
            margin-top: 0;
        }
    }
}

.listItem {
    margin-top: spacing(2);

    & > * {
        display: block;
        padding: spacing(2, 0);
        color: var(--color-gray-03);
        text-decoration: none;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

.copyrightColumn {
    flex: 0 0 100%;
    margin-top: var(--distance-extra-small);

    @media screen and (width >= 992px) {
        flex: 0 0 255px;
        margin-top: 0;
    }
}

.columnContainer {
    display: flex;
    flex: 0 0 100%;

    @media screen and (width >= 992px) {
        flex: 0 0 calc(100% - 255px);
        padding-left: var(--distance-small);
    }

    @media screen and (max-width: $screen-size-sm) {
        flex-direction: column;
    }
}

.marketColumn {
    flex: 0 0 50%;

    @media screen and (width >= 992px) {
        flex: 0 0 66.66%;
    }

    > span {
        margin: 0;
    }
}

.paymentColumn {
    flex: 0 0 50%;
    gap: var(--distance-extra-small);

    @media screen and (width >= 992px) {
        flex: 0 0 33.33%;
    }
}

.bbLink {
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important; // Temporary hack
}

.row {
    flex-direction: column;

    @media screen and (width >= 992px) {
        flex-direction: row !important;
    }
}

.cookieSettingsButton {
    padding: 0 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    color: var(--color-gray-03) !important;
    text-decoration: none !important;
    border: none !important;

    &:hover {
        color: white !important;
        text-decoration: underline !important;
        background-color: inherit !important;
    }
}

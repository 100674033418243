.base {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.visaElectron {
    height: 25px;
}

.visa {
    height: 22px;
}

.mastercard {
    height: 25px;
}

.dankort {
    height: 25px;
}
